import {pk_test, pk_live, charge_endpoint, domain} from './json-data/stripe.json'
import {ICalParser} from 'cozy-ical';


export default class Service {

    static get stripe_key() {
        const isProd = window.location.hostname.indexOf(domain) > -1;
        return isProd ? pk_live : pk_test;
    }

    static xhrRequest(href, method, data, timeout = 30000, json = true) {
        return new Promise((resolve, reject) => {
            const xhr = new window.XMLHttpRequest();
            xhr.open(method, href, true);
            if (json) xhr.setRequestHeader('content-type', 'application/json;charset=utf-8');
            xhr.timeout = timeout;
            xhr.ontimeout = reject;
            xhr.onerror = reject;
            xhr.onload = _ => xhr.status === 200 ?
                resolve(xhr.response) : reject(xhr.response);
            xhr.send(data);
        });
    }

    static runCharge(token, amount, memo) {
        return new Promise((resolve, reject) => {
            Service.xhrRequest(charge_endpoint, 'POST', JSON.stringify({
                "amount": amount,
                "charge_description": memo || `Payment on ${domain}`,
                "source": token
            }))
                .then(resolve)
                .catch(reject);
        });
    }

    static parseICal = (data, cb) => new ICalParser().parseString(data, (err, cal) => {
        const {subComponents} = cal;
        const events = subComponents.map(({model}) => {
            model.description = decodeURIComponent(model.description || '');
            return model;
        }).filter(e => {
            console.log(e);
            return !e.startDate || e.startDate.getTime() > (Date.now() - 24 * 60 * 60 * 1000)
        });
        return cb(events);
    });

    static fetchCalendar() {
        const iCalURL = 'https://cors-anywhere.herokuapp.com/https://calendar.google.com/calendar/ical/mobilefirst.me_mc2shqefm7vugiofksrbboosn0%40group.calendar.google.com/public/basic.ics';
        return Service.xhrRequest(iCalURL, 'GET', null, 20000, false)
    }
}
