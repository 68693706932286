import React from 'react';
import MetaTags from 'react-meta-tags';
import JSON_LD from "./json-data/jsonld";
import PropTypes from 'prop-types'

export default class MetaComponent extends React.Component {

    renderMeta = (name, description, url, json) => {
        let formatName = (name === "Mobile First" ? name +
            " | Application Design and Software Development" : name);
        document.title = formatName;
        return (<MetaTags>
            <title>{formatName}</title>
            <meta property="og:title" content={formatName}/>
            <meta property="og:site_name" content={name}/>
            <meta property="twitter:title" content={name}/>
            <meta name="description" content={description}/>
            <meta name="og:description" content={description}/>
            <meta name="twitter:description" content={description}/>
            <link rel="canonical" href={url}/>
            <meta property="og:url" content={url}/>
            <meta property="twitter:url" content={url}/>
            <script className='structured-data-list' type="application/ld+json">{JSON.stringify(json)}</script>
        </MetaTags>)
    };

    renderUsingLdJson() {
        const {pagepath} = this.props,
            json = JSON_LD[pagepath],
            {name, description, url} = json;
        return this.renderMeta(name, description, url, json)
    }

    render() {
        const {name, description, url} = this.props;
        if (name && description && url) {
            return this.renderMeta(name, description, url, '{}')
        } else
            return this.renderUsingLdJson();
    }
}

MetaComponent.propTypes = {
    pagepath: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string
}
