import React from 'react';

export default function Loader({light, position, height}) {
    return <div style={{
        display: 'flex',
        position: position || 'fixed',
        height: height || '100vh',
        width: '100%',
        overflow: 'hidden',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <div className={"loader" + (light ? " light" : "")}/>
    </div>
}
