import React from 'react';
import {Route, Switch} from 'react-router-dom'
import {Elements, StripeProvider} from 'react-stripe-elements';
import svc from './service';
import Loader from './components/loader'

const LandingPage = React.lazy(() => import('./page-landing/LandingPage'));
const ContactPage = React.lazy(() => import('./page-contact/Contact'));
const ProjectsPage = React.lazy(() => import('./page-apps/Apps'));
const ToolsPage = React.lazy(() => import('./page-platforms/Tools'));
const PricingPage = React.lazy(() => import('./page-pricing/Pricing'));
const PaymentPage = React.lazy(() => import('./page-payment/Payment'));
const AboutPage = React.lazy(() => import('./page-about/About'));
const WorkshopPage = React.lazy(() => import('./page-workshops/Workshops'));
const FeedbackPage = React.lazy(() => import('./page-feedback/Feedback'));
const PrivacyPage = React.lazy(() => import('./page-privacy/Privacy'));


export default class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {stripe: null};
    }

    componentDidMount() {
        if (window.location.href.indexOf('payment') > 0) {
            const that = this;
            const loadStripe = function () {
                if (window.Stripe) {
                    that.setState({stripe: window.Stripe(svc.stripe_key)});
                } else if (document.querySelector('#stripe-js').length) {
                    document.querySelector('#stripe-js')
                        .addEventListener('load', () => {
                            that.setState({stripe: window.Stripe(svc.stripe_key)});
                        });
                } else {
                    window.setTimeout(loadStripe, 100);
                }
            };
            loadStripe();
        }
    }

    render() {
        return <div>
            <React.Suspense fallback={<Loader/>}>
                <Switch>
                    <Route exact path="/blog" render={_ => {window.location.href = "/blog/"}}/>
                    <Route path="/contact-us" component={_ => <ContactPage/>}/>
                    <Route path="/apps" component={_ => <ProjectsPage/>}/>
                    <Route path="/workshops" component={_ => <WorkshopPage/>}/>
                    <Route path="/platforms" component={_ => <ToolsPage/>}/>
                    <Route path="/pricing" component={_ => <PricingPage/>}/>
                    <Route path="/augusta" component={_ => <LandingPage />}/>
                    <Route path="/about" component={_ => <AboutPage />}/>
                    <Route path="/privacy" component={_ => <PrivacyPage />}/>
                    <Route path="/feedback" component={_ => <FeedbackPage />}/>
                    <Route path="/payment" component={_ =>
                        <StripeProvider stripe={this.state.stripe}>
                            <Elements>
                                <PaymentPage/>
                            </Elements>
                        </StripeProvider>}/>
                    <Route component={_ => <LandingPage/>}/>
                </Switch>
            </React.Suspense>
        </div>
    }
}
