import React from 'react';
import {Component} from 'react'
import {withRouter} from 'react-router-dom'
import GTAG from "./SEO";
import MetaComponent from './metaComponent'


/**
 * @class
 * @classdesc This component sets scroll position to window top whenever url route change is detected.
 */
class PageChange extends Component {

    constructor(props) {
        super(props);
        GTAG.registerErrorLogger();
    }

    pageKey = () => {
        const path = window.location.pathname.toLowerCase();
        if (path.startsWith('/contact-us')) {
            return 'Contact_us'
        }
        if (path.startsWith('/apps')) {
            return 'Apps'
        }
        if (path.startsWith('/platforms')) {
            return 'Platforms'
        }
        if (path.startsWith('/about')) {
            return 'About'
        }
        if (path.startsWith('/pricing')) {
            return 'Pricing'
        }
        if (path.startsWith('/workshops')) {
            return 'Workshops'
        }
        if (path.startsWith('/payment')) {
            return 'Payment'
        }
        return 'Landing_page'
    };

    /**
     * @description When app route changes check location and scroll to top if path has changed
     * @param {Object} prevProps - previous properties
     */
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location && !this.props.location.hash) {
            GTAG.pageView();
            window.scrollTo(0, 0)
        }
    }

    /**
     * This component does not render any elements but will
     * render any/all child components as-is
     */
    render() {
        return [<MetaComponent pagepath={this.pageKey()} key={1}/>, this.props.children]
    }
}

export default withRouter(PageChange)
